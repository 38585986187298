<template>
  <div class="hello">
      <div class="bgpop" v-if="loadShow" style="z-index:11111;"></div>
      <div v-if="loadShow" class="load_div"><i class="el-icon-loading"></i></div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  data () {
    return {
      loadShow: false
    }
  },
  methods: {
    show: function(){
      this.loadShow = true;
    },
    hide: function(){
      this.loadShow = false;
    }
  }
}
</script>

<style scoped>
.bgpop{width:100%;height:100%;position:fixed;top:0;left:0;background:#000;z-index:1000;filter:alpha(opacity=60);opacity:0.6;-moz-opacity:0.6;}
/* .bgpop.check{display: none} */
.load_div{
    z-index:99999;width:40px;height:40px;position:fixed;top:0;right:0;bottom:0;left:0;margin:auto;
    animation:loadmove 0.8s infinite;animation-fill-mode: forwards;
    color:#FFF;text-align:center;line-height:20px;font-size:40px;
}
@keyframes loadmove{
    100% {transform: rotate(360deg);}
}
</style>


