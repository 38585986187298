<template>
  <div class="hello">
      <div class="bgpop" v-if="alertShow" style="z-index:11100;"></div>
      <div class="com_div" v-if="alertShow">
        <div class="com_tit">提示</div>
        <div class="com_bg">
          <div class="com_content"><span>{{ alertText }}</span></div>
          <div class="com_btn"><span class="s" v-on:click="closeAlert()">确定</span></div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  data () {
    return {
      alertText: '',
      alertShow: false,
      backFn: ''
    }
  },
  methods: {
    show: function(txt, backfn){
      this.backFn = ''; 
      this.alertShow = true;
      this.alertText = txt;
      if(backfn){
        this.backFn = backfn;
      }
    },
    closeAlert: function(){
      if(this.alertText.indexOf("未登录") != -1){
        this.$router.push('/');
      }
      if(this.alertText.indexOf("请登录") != -1){
        this.$router.push('/');
      }
      if(this.alertText.indexOf("重新登录") != -1){
        this.$router.push('/');
      }
      this.alertShow = false;
      this.alertText = "";
      if(this.backFn){
        this.backFn();
      }
    },
    closeAlertX: function(){
      this.alertShow = false;
      this.alertText = "";
    }
  }
}
</script>
<style scoped>
.bgpop{width:100%;height:100%;position:fixed;top:0;left:0;background:#000;z-index:1000;filter:alpha(opacity=60);opacity:0.6;-moz-opacity:0.6;}
.bgpop.check{display: none}
.com_div{
    width:320px;height:170px;position:fixed;z-index:11152;box-shadow:0px 0px 10px #BBBCBE;border-radius:3px;
    top:0;right:0;bottom:0;left:0;margin:auto;
}
.com_div .com_bg{width:320px;height:130px;background:#FFF;border-radius:0 0 4px 4px;}
.com_div .com_tit{height:40px;line-height:40px;color:#FFF;font-size:14px;text-align:center;background:#26A8EB;border-radius:4px 4px 0 0;}
.com_div .com_close{
    /* cursor:pointer;width:18px;height:18px;background:url(../img/alert_close.png) no-repeat;margin-top:11px; */
    position:absolute;right:10px;
}
/* .com_div .com_close:hover{background:url(../img/alert_close_h.png) no-repeat;} */
.com_div .com_content{width:100%;text-align:center;font-size:13px;color:#666666;height:90px;line-height:90px;}
.com_div .com_content span{display: inline-block;vertical-align: middle;line-height:22px;width:90%;}
.com_div .com_btn{text-align:center;}
.com_div .com_btn span.s{
    display:inline-block;width:70px;letter-spacing:3px;background:#26A8EB;padding:6px 0;color:#FFF;cursor:pointer;border-radius:4px;
}
.com_div .com_btn span.cs{
    display:inline-block;width:68px;letter-spacing:3px;background:#FFF;padding:5px 0;color:#333;cursor:pointer;border-radius:4px;
    border:1px solid #26A8EB;margin-left:5px;
}
.com_div .com_btn span.cc{display:inline-block;letter-spacing:3px;background:#ff8a44;padding:5px 10px;color:#333;cursor:pointer;color:#FFF;margin:0 5px;border-radius:5px;}
.com_div .com_btn span.s:hover{box-shadow:0px 0px 10px #BBBCBE;}
.com_div .com_btn span.cs:hover{box-shadow:0px 0px 10px #BBBCBE;}

</style>


