import Vue from 'vue'
import Router from 'vue-router'
import index from '@/components/index'
import classList from '@/components/classList'
import teacherLive from '@/components/teacherLive'



Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',                              //首页
      component: index,
      children: [
        {
          path: '/index',                               //首页
          component: index
        }     
        
      ]
   },
   {
    path: '/classList',                  //课程列表
    component: classList
   },
   {
    path: '/teacherLive',                  //老师直播
    component: teacherLive
   },
   


  ]
})
