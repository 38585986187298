<template>
  <div class="common"></div>
</template>

<script>
export default {
  name: "common",
  components: {},
  publicDef: {
    newPath: "https://school.classxw.com/",
    newDomain: window.location.hostname
  },
  getCookie: function(name) {
    var arr,
      reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if ((arr = document.cookie.match(reg))) return arr[2];
    else return null;
  },
  setCookie: function(c_name, value, expiredays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    document.cookie =
      c_name +
      "=" +
      escape(value) +
      (expiredays == null
        ? ""
        : ";domain=" +
          this.publicDef["newDomain"] +
          ";path=/;expires=" +
          exdate.toGMTString());
  },
  delCookie: function(name) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = this.getCookie(name);
    if (cval != null)
      document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
  },
  getUrlPara: function(name) {
    //获取url上传的参数
    var r = "";
    if (window.location.href.indexOf("?") != -1) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      r = window.location.href.split("?")[1].match(reg);
      if (r != null) return decodeURIComponent(r[2]);
      return "";
    } else {
      return "";
    }
  },
  data() {
    return {
      msg: ""
    };
  }
};
</script>


