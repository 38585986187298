<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  mounted(){

  },
  methods: {

    
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-width: 1200px;
  width: 100%;
  height: 100%;
  /* color: #2c3e50; */
}
a {
  text-decoration: none;
  color: #333;
}
a:hover{
    color: #0457B9;
    text-decoration:none;
}





</style>
