<template>
  <div class="hello">

    <div class="list_tit">
        我的课程
      <div class="loginOut" @click="loginOut">退出登录</div>
    </div>
    <!-- <div class="list_big_nav">
      <div class="list_nav" id="listNav">
          <div class="cur" state="1">待上课</div>
          <div state="3">已结束</div>
      </div>
    </div> -->
    <div class="list_list_div" v-if="classList.length>0">

      <div class="courseDetail" v-for="c in classList" :key="c.id">
        <div class="courseNameDiv">
          <div class="courseName" :title="c.courseName">{{ c.courseName }}</div>
          <div class="courseTime">{{ c.bTimeStr.slice(0,16)}} - {{ c.eTimeStr.slice(11,16) }}</div>
        </div>
        <div class="courseBtn">
          <div class="joinBtn" @click="joinRoom(c.courseName,c.courseId,c.roomId)">进入直播</div>
        </div>
      </div>

      <el-pagination style="margin-bottom: 40px;" @current-change="getClassList" background layout="prev, pager, next" :current-page='pageNum' :page-count='pagecount' :page-size="pageSize" > </el-pagination>


            
    </div>

    <div class="list_list_div" v-if="classList.length==0">

      <div class="null_word"><img src="@/assets/teaImg/noClass.png"><div>近期没有安排课程</div></div>

            
    </div>

    
    <ssAlert ref="childAlert"></ssAlert>
    <ssLoading ref="childLoading"></ssLoading> 

  </div>
</template>

<script>

import ssAlert from "@/components/commonVue/Alert";
import ssLoading from "@/components/commonVue/Loading";

export default {
  name: 'classList',
  components: {
    ssAlert,
    ssLoading
  },
  data(){
    return {
      path: this.newCommon["publicDef"]["newPath"],
      pageSize: 10,
      pageNum: 1,
      pagecount: 1,
      classList: [],
      teacherId: '',
      account: '',
      access_token: '',
    }

  },
  props: {

  },
  mounted() {
    this.teacherId = localStorage.getItem("teaId");
    this.account = localStorage.getItem("phone");
    this.access_token = localStorage.getItem("token");
    this.getClassList(this.pageNum);

    
  },
  methods: {
    getClassList(pageNum) {
      this.$refs.childLoading.show();
      this.axios({
        url:  this.path + "api/teacher/getCourses",
        method:"post",
        params: {
          "pageNum": pageNum,
          "pageSize": this.pageSize,
          "teacherId": this.teacherId,
          "account": this.account,
          "access_token": this.access_token
        }
      }).then( (response)=> {
          this.$refs.childLoading.hide();
          let sessDta = response.data;
          if(sessDta.code == 200){
            this.classList = sessDta["data"]["list"]; 	
            this.pagecount = sessDta.data.pages;
           
          }else {
            this.$refs.childAlert.show(sessDta.msg);
          }
          
          
      }).catch( ()=> {
          this.$refs.childLoading.hide();
          this.$refs.childAlert.show("网络异常");
      });

      
      
      
    },
    joinRoom(courseName,courseId,roomId){  
      this.$refs.childLoading.show();
      this.axios({
        url:  this.path + "api/teacher/permitOpenRoom",
        method:"post",
        params: {
          "courseId": courseId	
        }
      }).then( (response)=> {
          this.$refs.childLoading.hide();
          let sessDta = response.data;
          if(sessDta.code == 200){
            localStorage.setItem("courseId", courseId);
            localStorage.setItem("roomId", roomId);
            localStorage.setItem("courseName", courseName);
            this.getUserSign()
           
          }else {
            this.$refs.childAlert.show(sessDta.msg);
          }
          
          
      }).catch( ()=> {
          this.$refs.childLoading.hide();
          this.$refs.childAlert.show("网络异常");
      });

 
      
    },
    getUserSign(){  // 获取 userSign

      this.$refs.childLoading.show();
      this.axios({
        url:  this.path + "api/get",
        method:"post",
        params: {
          "account": this.account,		
          "access_token": this.access_token		
        }
      }).then( (response)=> {
          this.$refs.childLoading.hide();
          let sessDta = response.data;
          if(sessDta.code == 200){
            console.log(sessDta["data"] +'---')
            localStorage.setItem("userSign", sessDta["data"]);
            this.$router.push('/teacherLive');	
           
          }else {
            this.$refs.childAlert.show(sessDta.msg);
          }
          
          
      }).catch( ()=> {
          this.$refs.childLoading.hide();
          this.$refs.childAlert.show("网络异常");
      });

      
      

    },
    loginOut(){
      this.$router.push('/');
      //清空所有浏览器缓存
      localStorage.clear();

    }

  }
}
</script>

<style>
body{
  background-color: #F7F7F7 !important;
}
/*我的课程*/
.list_tit{width:100%;text-align:center;font-size:22px;font-weight:bold;background-color: #fff;
height: 60px;
line-height: 60px;
position: relative;
}
.list_list_div{
	width:1080px;
	margin:20px auto;
	padding: 10px 40px;
	box-sizing: border-box;	
}
.courseDetail {
	width: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	flex-flow:  row nowrap;
	/*justify-content: space-between;*/
	justify-content: center;
	height: 80px;
	text-align: center;
	background-color: #fff;
	border-radius: 10px;
	margin: 20px auto;
}
.courseNameDiv {
	width: 100%;
	padding-left: 20px;
	box-sizing: border-box;
}
.courseTime {
	width: 100%;
	font-size: 12px;
	color: #666;
	text-align: left;
}
.courseName {
	width: 100%;
	text-align: left;
	font-size: 16px;
	font-weight: 700;
	margin: 15px auto;
	word-break:break-all;
    display:-webkit-box;
    -webkit-line-clamp:1;
    -webkit-box-orient:vertical;
    overflow:hidden;
	color: #333;
}
.liveName {
	font-size: 16px;
	height: 40px;
	line-height: 40px;
	word-break:break-all;
    display:-webkit-box;
    -webkit-line-clamp:1;
    -webkit-box-orient:vertical;
    overflow:hidden;
	color: #333;
}
.schoolName {
	font-size: 12px;
	color: #cecece;
	height: 30px;
	line-height: 30px;
	word-break:break-all;
    display:-webkit-box;
    -webkit-line-clamp:1;
    -webkit-box-orient:vertical;
    overflow:hidden;
}
.courseBtn {
	width: 200px;
}
.joinBtn {
	display:inline-block;
	width:100px;
	height:30px;
	text-align:center;
	line-height:30px;
	/*background:#F5CA1F;*/
	background:#2F5AFF;
	color:#FFF;
	cursor:pointer;
	border-radius:30px;
	font-size: 12px;	
	position:relative;
	top:30px;
}
.joinBtn:hover{background:#008AEB;}


.null_word{color:#cecece;text-align:center;font-size:12px;}
.null_word img {
	width: 200px;
}

.footer{width:100%;background:#434343;color:#9D9D9D;text-align:center;padding:15px 0;}
.footer>p{margin-top:18px;}
.footer>p>a{color:#9D9D9D;margin-left:5px;}
.footer>p>a:hover{color:#FFF;}

.loginOut {
	width: 80px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	position: absolute;
	top: 15px;
  right: 50px;
	border-radius: 4px;
	background-color: #2F5AFF;
	color: #fff;
	font-size: 12px;
	cursor: pointer;
}

.list_big_nav{background:#FFF;padding:15px 0;}
.list_nav{width:300px;margin:0 auto;overflow:hidden;}
.list_nav>div{width:148px;border:1px solid #CCC;float:left;height:30px;line-height:30px;text-align:center;font-size:14px;cursor:pointer;}
.list_nav>div:first-child{border-right:0;}
.list_nav>div.cur{background:#2F5AFF;color:#FFF;}


</style>
