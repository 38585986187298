<template>
  <div class="hello" style="width: 100%;height: 100%;">

    <div id="app_box">

        <div class="liveHeader" style="padding: 20px 0;">
            <el-button style="float: right;margin-right: 50px;" @click="stopLive" v-if="isPushing == 1" size="mini">下课</el-button>
            <el-button @click="showCamera" size="mini">选择摄像头/麦克风</el-button>
            <audio id="audioPlay" preload="auto" style="display: none;" controls="controls" src="@/assets/teaImg/ckwt.mp3"></audio>

        </div>

        <div id="video_wrap" class="oneStu">
        </div>

        <!--中间区  信息区 -->
        <el-footer style="position: absolute;top: 0;right: 0;width: 340px;bottom: 0;margin:auto;height: 100%;background-color: #fff;border: 1px solid #999;display: none;">
          <el-container>
            <el-main>
              <ul id="msg_box" class="im-log-box">
                <li v-for="(msg,i) in msgs" :key="i">
                  <span class="send">{{msg.time}}</span>
                  <span class="send">{{msg.send}}</span>
                  <span class="content">{{msg.content}}</span>
                </li>
              </ul>
            </el-main>
            <el-footer style="margin-top: 5px;">

              <el-input v-model="imMsg.common.data" placeholder="text消息"></el-input>
              <el-button type="primary" @click="sendMsg">发送消息</el-button>
            </el-footer>
          </el-container>
        </el-footer>

        

        <!--遮罩层-->
        <div class="livezhezhao" v-if="hideshow"></div>

        <!-- 选择摄像头弹窗 -->
        <div class="cameraChoseDiv" v-if="choseCamShow">

        <div style="font-size: 20px;font-weight: 700;color: #333;text-align: center;margin-bottom: 20px">选择设备</div>

        <div class="choseDiv cameraDiv">
            <span>摄像头：</span>
            <el-select v-model="cameraId" @change="changeCamer" placeholder="请选择">
                <el-option
                  v-for="item in devices.camera"
                  :key="item.deviceId"
                  :label="item.label"
                  :value="item.deviceId">
                </el-option>
            </el-select>
        </div>

        <div class="choseDiv micDiv">
            <span>麦克风：</span>
            <el-select v-model="micId" @change="changeMic" placeholder="请选择">
                <el-option
                  v-for="item in devices.mic"
                  :key="item.deviceId"
                  :label="item.label"
                  :value="item.deviceId">
                </el-option>
            </el-select>

        </div>

        <div @click="choseCamerBtn" class="cameraBtn">确定</div>
        
        </div>

        

        <!-- 提示弹窗 -->
        <div class="tsDiv" v-if="tsDivShow">
        {{ tsText }}
        </div>


        <!-- 问题反馈弹窗 -->
        <div class="questionDiv" v-if="questionShow">

        <div style="font-size: 16px;font-weight: 700;color: #333;text-align: center;margin: 10px auto">问题描述</div>

        <div class="questionList" style="text-align:left;">
            <div class="questionDetail" v-for="q in questionList">
            <div class="qustionFrom">问题来自---{{ q.schoolName }}</div>
            <div class="questionContent">{{ q.content }}</div>
            </div>
        </div>


        <div v-on:click="ikonwBtn" class="ikonw">我知道了</div>
        
        </div>

        <!-- 下课弹窗 -->
        <div class="xkDiv" v-if="xkShow">
            <div style="font-size: 16px;font-weight: 700;color: #333;text-align: center;">提示</div>
            <div>您确定下课吗？</div>
            <div v-on:click="stopClass" class="xkBtn">确定</div>
            <div v-on:click="closeClass" class="qxBtn">取消</div>
        </div>

    </div>

    

    
    <ssAlert ref="childAlert"></ssAlert>
    <ssLoading ref="childLoading"></ssLoading> 

  </div>
</template>

<script>
import $ from "jquery";
// import rtc from './mixins/rtc.js';
import TRTC from 'trtc-sdk-v5';
import ssAlert from "@/components/commonVue/Alert";
import ssLoading from "@/components/commonVue/Loading";

import TencentCloudChat from '@tencentcloud/chat';
import TIMUploadPlugin from 'tim-upload-plugin';
import TIMProfanityFilterPlugin from 'tim-profanity-filter-plugin';



export default {
  name: 'teacherLive',
  components: {
    ssAlert,
    ssLoading,
  },
  data(){
    return {
      path: this.newCommon["publicDef"]["newPath"],
      // mixins: [rtc],
      hideshow: true,
      choseCamShow: true,
      questionShow: false,
      tsDivShow: false,
      xkShow: false,
      courseName: '',
      users: '',
      token: '',
      account: '',
      sdkAppId: '',
      userSig: '',
      roomId: '',
      tsText: '',
      nowSpeakUserId: '',
      //消息
      msgs: [],
      imMsg: {
        common: {},
        custom: {}
      },
      //音视频及设备
      enableCamera: true,
      enableMic: true,
      cameraId:'',
      micId:'',
      cameraIndex: 0,
      micIndex: 0,
      devices: {
        camera: [],
        mic: []
      },
      isShow: false,
      imReady: false,
      clickNum: 1,
      isPushing: 0, // 是否正在推流
      isPushCamera: 0, // 是否推摄像头流
      inUserIds: [],
      questionList: []
    }

  },
  props: {

  },
  mounted() {
    this.account =  localStorage.getItem("phone");
    this.roomId =  localStorage.getItem("roomId");  
    this.courseId =  localStorage.getItem("courseId");
    this.teacherId =  localStorage.getItem("teaId");
    this.token =  localStorage.getItem("token");
    this.courseName = localStorage.getItem("courseName");
    document.title = this.courseName;
    this.sdkAppId =  '1400370768';
    this.userSign = localStorage.getItem("userSign");

    navigator.mediaDevices
    .getUserMedia({ video: true, audio: true })
    .then(() => {
      this.getCameraDevices();
      this.getMicDevices(); 
    })
    .catch(err => {
      console.log('getUserMedia err', err.name, err.message);
      this.getCameraDevices();
      this.getMicDevices(); 
      // handleGetUserMediaError(err);
    });

    
    this.trtc = TRTC.create();
    this.trtc.stopLocalVideo();
    this.trtc.stopLocalAudio();
    this.init();

    $("body").keyup(function () {
      if (event.which == 27){
        $(".lmBtn").parent().removeClass("fullscreen");
      }
    });
  },
  beforeDestroy(){
    this.trtc.stopLocalVideo();
    this.trtc.stopLocalAudio();
  },
  methods: {
    showTip(title, time) {
      this.$message({
        showClose: true,
        message: title,
        type: 'success'
      });
    },
    init(){
      let options = {
        SDKAppID: this.sdkAppId // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
      };
      // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
      this.chat = TencentCloudChat.create(options); // SDK 实例通常用 chat 表示

      this.chat.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
      // this.chat.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用


      // 注册腾讯云即时通信 IM 上传插件
      this.chat.registerPlugin({'tim-upload-plugin': TIMUploadPlugin});


      // 注册腾讯云即时通信 IM 本地审核插件
      this.chat.registerPlugin({'tim-profanity-filter-plugin': TIMProfanityFilterPlugin});

      let promise = this.chat.login({userID: this.account , userSig: this.userSign});
      promise.then((imResponse)=> {
        console.log(imResponse); // 登录成功
        console.log("IM登录成功"); // 登录成功
        this.chat.on(TencentCloudChat.EVENT.SDK_READY, this.onSdkReady);
        if (imResponse.data.repeatLogin === true) {
          // 标识账号已登录，本次登录操作为重复登录。
          // console.log(imResponse.data.errorInfo);
        }
      }).catch(function(imError) {
        console.warn('login error:', imError); // 登录失败的相关信息
      });

      this.chat.on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, (event)=> {
        // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
        // event.name - TencentCloudChat.EVENT.MESSAGE_RECEIVED
        // event.data - 存储 Message 对象的数组 - [Message]

        let fromUserId = event.data[0].from;
        let text = event.data[0].payload.text;
        var d = new Date();
        var time = `${('0' + d.getHours()).substr(-2)}:${('0' + d.getMinutes()).substr(-2)}:${('0' + d.getSeconds()).substr(-2)}.${('00' + d.getMilliseconds()).substr(-3)}`
        if(text=='whoisteacher'){   //谁是老师
          this.sendCustomGroupMsg(null, 'iamteacher|'+ this.account +'');
        }else if(text.indexOf('schoolName|')!=-1){
          let schoolName = text.split('|')[1];
          let schoolId = text.split('|')[2];
          let stuDivs = $(".stuDiv");
          for(let i=0;i<stuDivs.length;i++){
            var stuSchoolId = $(stuDivs[i]).attr('schoolId')
            if(stuSchoolId == schoolId ){
              let namediv = $('<div class="schoolNameDiv"><div class="nameDiv">学校：'+ schoolName +'</div><div class="nameDiv">账号：'+ schoolId +'</div></div>');
              namediv.appendTo(stuDivs[i]);
              
            }
          }
        }else if(text.indexOf('xwmessageContent|')!=-1){
          
          let nickName = text.split('|')[1];
          let messageContent = text.split('|')[2];
          this.questionList.push({
            "schoolName": nickName,
            "content": messageContent
          });
          this.playAudio();
          
          this.hideshow = true;
          this.questionShow = true;
        }

        this.msgs.push({
          time: time,
          send: fromUserId + '：',
          content: text
        });
        this.$nextTick(() => {
          var msgbox = document.querySelector("#msg_box");
          msgbox.scrollTop = msgbox.scrollHeight;
        });

      });

      this.chat.on(TencentCloudChat.EVENT.NET_STATE_CHANGE, function(event) {
        if(event.data.state == TencentCloudChat.TYPES.NET_STATE_CONNECTING){
          this.showTip('连接中。。。。。。'); 
        }        
        // 网络状态发生改变
        // event.name - TencentCloudChat.EVENT.NET_STATE_CHANGE
        // event.data.state 当前网络状态，枚举值及说明如下：
        //   - TencentCloudChat.TYPES.NET_STATE_CONNECTED - 已接入网络
        //   - TencentCloudChat.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中”
        //   - TencentCloudChat.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息
      });

      




    },
    onSdkReady(){
      // 创建直播群
      this.chat.createGroup({
        groupID: this.roomId,
        type: TencentCloudChat.TYPES.GRP_AVCHATROOM,
        name: this.courseName,
      }).then((evevt)=>{
        console.log(evevt)
        this.chat.joinGroup({ groupID: this.roomId, type: TencentCloudChat.TYPES.GRP_AVCHATROOM }).then(()=>{
          this.imReady = true;
        });
      }).catch((evevt)=>{
        console.log(evevt)
        this.chat.joinGroup({ groupID: this.roomId, type: TencentCloudChat.TYPES.GRP_AVCHATROOM }).then(()=>{
          this.imReady = true;
        });
      });



      // this.chat.joinGroup({ groupID: this.roomId, type: TencentCloudChat.TYPES.GRP_AVCHATROOM }).then(()=>{
      //   this.imReady = true;
      // });
      // 该接口默认只会拉取这些资料：群类型、群名称、群头像、最后一条消息的时间。
      let promise = this.chat.getGroupList();
      promise.then(function(imResponse) {
        console.log(imResponse.data.groupList); // 群组列表
      }).catch(function(imError) {
        console.warn('getGroupList error。。。。。。。。。。。。。。:', imError); // 获取群组列表失败的相关信息
      });

      this.chat.on(TencentCloudChat.EVENT.KICKED_OUT, (event)=>{
        if(event.data.type == 'multipleAccount'){
          this.showTip('用户异地登录，请重新登录'); 
          setTimeout(()=>{
              this.$router.push('/index');  
          },1000)
        }

        // TencentCloudChat.TYPES.KICKED_OUT_MULT_ACCOUNT(Web端，同一账号，多页面登录被踢)
        // TencentCloudChat.TYPES.KICKED_OUT_MULT_DEVICE(同一账号，多端登录被踢)
        // TencentCloudChat.TYPES.KICKED_OUT_USERSIG_EXPIRED(签名过期)
        // TencentCloudChat.TYPES.KICKED_OUT_REST_API(REST API kick 接口踢出)

      });

      // let promise1 = this.chat.getGroupProfile({ groupID: this.roomId, groupCustomFieldFilter: ['key1','key2'] });
      // promise1.then(function(imResponse) {
      //   console.log(imResponse.data.group + '.。。。。。。。。。。。。。。。。。。。。。。。。。。。。。');
      // }).catch(function(imError) {
      //   console.warn('getGroupProfile error:', imError); // 获取群详细资料失败的相关信息
      // });

    },
    showCamera() {
      this.clickNum = 2;
      this.hideshow = true;
      this.choseCamShow = true;
    },
    playAudio(){
      var audio=document.getElementById('audioPlay');
      audio.play();
    },
    choseCamerBtn (){
        
      if(!this.cameraId){
        this.$refs.childAlert.show("请选择摄像头");
        return false;
      }

      if(!this.micId){
        this.$refs.childAlert.show("请选择麦克风");
        return false;
      }

      this.hideshow = false;
      this.choseCamShow = false; 

      if(this.clickNum == 1){       
        this.openCourseView();        
      }
    },
    // 开始直播接口
    openCourseView() {

      this.$refs.childLoading.show();
      this.axios({
        url:  this.path + "api/tea/openCourseView",
        method:"post",
        params: {
          "account": this.account,   
          "teacherId": this.teacherId,   
          "courseId": this.courseId,   
          "access_token": this.token  
        }
      }).then( (response)=> {
          let sessDta = response.data;
          this.$refs.childLoading.hide();
          if(sessDta.code == 200){
            this.joinClassRoom();  
          }else {
            this.$refs.childAlert.show(sessDta.msg);
          }
          
      }).catch( (error)=> {
          this.$refs.childLoading.hide();
          this.$refs.childAlert.show("网络异常");
      });
    },
    joinClassRoom (){
      this.trtc.enterRoom({ roomId: Number(this.roomId), scene:'rtc', sdkAppId: Number(this.sdkAppId), userId: this.account, userSig: this.userSign }).then(()=> {
        this.installEventHandlers();
        this.startGetAudioLevel();
        if(this.clickNum == 1){
          this.startRTC(); //自动开启直播
          this.sendCustomGroupMsg(null, 'iamteacher|'+ this.account +'');            
          this.clickNum = 2;  
        }

        // let ImOptions ={
        //   name: this.courseName,
        //   groupID: this.roomId,
        //   type: TencentCloudChat.TYPES.GRP_AVCHATROOM
        // };

        // this.chat.createGroup(ImOptions).then(()=>{
        //   this.joinImRoom();
        // }).catch(()=>{
        //   this.joinImRoom();
        // })
           
      }).catch((error)=> {
        console.error('进房失败 ' + error);
      });

      // try {
      //   await this.trtc.enterRoom({ roomId: Number(this.roomId), scene:'rtc', sdkAppId: Number(this.sdkAppId), userId: this.account, userSig: this.userSign });
      //   this.installEventHandlers();
      //   this.startGetAudioLevel();
      //   if(this.clickNum == 1){
      //     this.startRTC(); //自动开启直播            
      //     this.clickNum = 2;  
      //     // this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, (event)=>{
      //     //   alert(event)
      //     // });
      //   }
      // } catch (error) {
      //   console.error('进房失败 ' + error);
      // }
 

    },
    joinImRoom(){
      let promise = this.chat.joinGroup({ groupID: this.roomId, type: TencentCloudChat.TYPES.GRP_AVCHATROOM });
      promise.then(function(imResponse) {
        switch (imResponse.data.status) {
          case TencentCloudChat.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
            break;
          case TencentCloudChat.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
            console.log(imResponse.data.group); // 加入的群组资料
            this.sendCustomGroupMsg(null, 'iamteacher|'+ this.account +'');
            break;
          case TencentCloudChat.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
            this.sendCustomGroupMsg(null, 'iamteacher|'+ this.account +'');
            break;
          default:
            break;
        }
      }).catch(function(imError){
        console.warn('joinGroup error:', imError); // 申请加群失败的相关信息.
      });

    },
    // 启动推流(推摄像头)
    startRTC() {
      var localVideoWrapEl = document.getElementById('local_video');
      if (!localVideoWrapEl) {
        localVideoWrapEl = document.createElement('div');
        localVideoWrapEl.id = 'local_video';
        document.querySelector("#video_wrap").prepend(localVideoWrapEl);
      }
      const view = 'local_video';
      this.trtc.startLocalVideo({
        view,
        // option.fillMode	'contain' | 'cover' | 'fill'
        option: {
          cameraId: this.cameraId
        }
      }).then(() => { 
        this.isPushing = 1;
        this.showTip('推流成功');
        console.log("发布本地视频成功。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。")
      });

      // this.trtc.updateLocalVideo({ 
      //   option: {
      //     cameraId: this.cameraId
      //   },publish:true }).then(() => { 
      //   this.isPushing = 1;
      //   console.log("推流成功。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。")
      // });

      this.trtc.startLocalAudio({ option: { microphoneId: this.micId }}).then(() => { 
        console.log("发布本地音频成功。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。")
      });

    
      
    },
    /**
     * 发送普通文本消息
     */
    sendMsg() {
      if (!this.imMsg.common.data) {
        this.showErrorTip(`不能发送空消息`);
        return;
      }

      // C2C 文本
      var text = this.imMsg.common.data;
      if (this.imMsg.common.toUser) {

        this.tic.sendTextMessage(this.imMsg.common.toUser, text, (res) => {
          if (res.code !== 0) {
            this.showMessageInBox('TIC', 'sendTextMessage failed, code=' + res.code + " content:" + text);
          } else {
            this.showMessageInBox(this.account, text);
          }
          console.log('===sendTextMessage:', res);
        });
      } else { // 群组 文本
        if (!this.roomID) {
          this.showErrorTip('发送群消息时，房间号为空');
          return;
        }

        this.tic.sendGroupTextMessage(text, (res) => {
          if (res.code !== 0) {
            this.showMessageInBox('TIC', 'sendGroupTextMessage failed, code=' + res.code + " content:" + text);
          } else {
            this.showMessageInBox(this.account, text);
          }
          console.log('===sendTextMessage:', res);
        });
      }

      this.imMsg.common.data = '';
    },
    /**
     * 发送自定义消息
     */
    sendCustomGroupMsg(toUser, todo) {
      // 
      this.imMsg.common.toUser = toUser;
      this.imMsg.custom.data = todo;
      // console.log()
      if (this.imMsg.common.toUser) { // C2C 自定义
        this.tic.sendCustomMessage(this.imMsg.common.toUser, this.imMsg.custom.data, function (res) {
          console.log('===sendCustomGroupMsg:', res);
        });
      } else { //群组 自定义
        // this.tic.sendGroupCustomMessage(this.imMsg.custom.data, function (res) {
        //   console.log('===sendCustomGroupMsg:', res);
        // });

        // 发送群消息
        let message = this.chat.createTextMessage({
          to: this.roomId,
          conversationType: TencentCloudChat.TYPES.CONV_GROUP,
          payload: {
            text: this.imMsg.custom.data
          },
          // 支持群消息已读回执功能，如果您发消息需要已读回执，需购买旗舰版套餐，并且创建消息时将 needReadReceipt 设置为 true
          needReadReceipt: true
        });
        // 发送消息
        let promise = this.chat.sendMessage(message);
        promise.then(function(imResponse) {
          // 发送成功
          // console.log(imResponse);
        }).catch(function(imError) {
          // 发送失败
          console.warn('sendMessage error:', imError);
        });


      }
    },
     /**
     * 结束推流
     */
    stopPush(callback) {
      if (this.isPushing) {
        this.trtc.updateLocalVideo({ publish:false }).then(() => {
          // 取消发布本地流成功
          this.isPushing = 0;
          document.getElementById('local_video').remove();
          if (Object.prototype.toString.call(callback) === '[object Function]') {
            callback();
          }
        });
      }

    },
    ikonwBtn() {
      this.questionList = [];
      this.hideshow = false;
      this.questionShow = false;
    },
    // 点击下课按钮
    stopLive() {
      this.hideshow = true;
      this.xkShow = true;
    },
    closeClass(){ //取消下课
      this.hideshow = false;
      this.xkShow = false;
    },
    stopClass(){ //下课弹窗     
      this.closeCourseView();
      this.hideshow = false;
      this.xkShow = false;
    },
    // 结束直播接口
    closeCourseView() {
      this.$refs.childLoading.show();
      this.axios({
        url:  this.path + "api/tea/closeCourseView",
        method:"post",
        params: {
          "account": this.account,   
          "teacherId": this.teacherId,   
          "courseId": this.courseId,   
          "connectTime": '',   
          "access_token": this.token 
        }
      }).then( (response)=> {
          let sessDta = response.data;
          this.$refs.childLoading.hide();
          if(sessDta.code == 200){
            this.sendCustomGroupMsg(null, 'stopLive');
            this.$refs.childAlert.show("课程结束",()=>{
              this.$router.push('/classList');  
            });
            this.trtc.exitRoom(); 
            // this.stopPush();
            this.trtc.stopLocalVideo();
            this.trtc.stopLocalAudio();
            // 退房成功后，若后续无需使用 trtc 实例，则可以调用 trtc.destroy 方法销毁实例，及时释放相关资源。销毁后的 trtc 实例无法继续使用，需要重新创建新的实例。
            this.trtc.destroy();
            setTimeout(()=>{
              this.$router.push('/classList');  
            },2000)

          }else {
            this.$refs.childAlert.show(sessDta.msg);
          }
          
      }).catch( (error)=> {
          this.$refs.childLoading.hide();
          this.$refs.childAlert.show("网络异常");
      });


    
    },
    /**
     * 枚举摄像头
     */
    getCameraDevices() {
      TRTC.getCameraList().then(devices => {
        this.devices.camera = devices;
      });
    },
    changeCamer(){
      if(this.clickNum != 1){       
        this.trtc.updateLocalVideo({ option: { cameraId: this.cameraId }}).then(() => { 
          console.log("切换摄像头成功。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。")
        });     
      }
      
    },  
    /**
     * 枚举麦克风
     */
    getMicDevices() {
      TRTC.getMicrophoneList().then(devices => {
        this.devices.mic = devices;
      });
    },
    changeMic(){
      if(this.clickNum != 1){       
        this.trtc.updateLocalAudio({ option: { microphoneId: this.micId }}).then(() => { 
          console.log("切换麦克风成功。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。")
        });   
      }
      
      
    },

    
    initTRTC() {
      if (this.trtc) return;
      this.trtc = TRTC.create();
    },

    async enterRoom() {
      this.roomStatus = 'entering';
      this.initTRTC();
      try {
        await this.trtc.enterRoom({
          roomId: this.roomId,
          sdkAppId: parseInt(this.sdkAppId, 10),
          userId: this.userId,
          userSig: this.userSig,
        });
        this.roomStatus = 'entered';

        this.installEventHandlers();
        this.startGetAudioLevel();
        this.reportSuccessEvent('enterRoom');
      } catch (error) {
        this.roomStatus = 'exited';
        console.error('enterRoom room failed', error);
        this.reportFailedEvent('enterRoom', error);
        throw error;
      }
    },

    async handleStartLocalAudio() {
      this.micStatus = 'starting';
      this.initTRTC();
      try {
        await this.trtc.startLocalAudio({
          option: {
            microphoneId: this.microphoneId,
          },
        });
        this.isMutedAudio = false;
        this.micStatus = 'started';
        this.reportSuccessEvent('startLocalAudio');
      } catch (error) {
        this.micStatus = 'stopped';
        this.reportFailedEvent('startLocalAudio', error.message);
        throw error;
      }
    },

    async handleStopLocalAudio() {
      if (this.micStatus !== 'started') {
        return;
      }
      this.micStatus = 'stopping';
      this.initTRTC();
      try {
        await this.trtc.stopLocalAudio();
        this.micStatus = 'stopped';
        this.reportSuccessEvent('stopLocalAudio');
      } catch (error) {
        this.micStatus = 'started';
        this.reportFailedEvent('stopLocalAudio', error.message);
        throw error;
      }
    },

    async handleStartLocalVideo() {
      this.camStatus = 'starting';
      this.initTRTC();
      try {
        await this.trtc.startLocalVideo({
          view: 'local',
          option: {
            cameraId: this.cameraId,
            profile: '480p',
          },
        });
        this.camStatus = 'started';
        this.isMutedVideo = false;
        this.reportSuccessEvent('startLocalVideo');
      } catch (error) {
        this.camStatus = 'stopped';
        this.reportFailedEvent('startLocalVideo', error.message);
        throw error;
      }
    },

    async handleStopLocalVideo() {
      if (this.camStatus !== 'started') {
        return;
      }
      this.camStatus = 'stopping';
      this.initTRTC();
      try {
        await this.trtc.stopLocalVideo();
        this.camStatus = 'stopped';
        this.reportSuccessEvent('stopLocalVideo');
      } catch (error) {
        this.camStatus = 'started';
        this.reportFailedEvent('stopLocalVideo', error.message);
        throw error;
      }
    },

    async exitRoom() {
      if (this.roomStatus !== 'entered') {
        return;
      }
      this.roomStatus = 'exiting';
      this.stopGetAudioLevel();

      try {
        await this.trtc.exitRoom();
        this.roomStatus = 'exited';
        this.remoteUsersViews = [];
        this.uninstallEventHandlers();
        this.reportSuccessEvent('exitRoom');
      } catch (error) {
        this.roomStatus = 'entered';
        this.reportFailedEvent('exitRoom', error);
        throw error;
      }

      if (this.micStatus === 'started') this.handleStopLocalAudio();
      if (this.camStatus === 'started') this.handleStopLocalVideo();
      if (this.shareStatus === 'shared') this.handleStopScreenShare();
    },

    async muteVideo() {
      try {
        await this.trtc.updateLocalVideo({ mute: true });
        this.isMutedVideo = true;
      } catch (error) {
      }
    },

    async muteAudio() {
      try {
        await this.trtc.updateLocalAudio({ mute: true });
        this.isMutedAudio = true;
      } catch (error) {
      }
    },

    async unmuteVideo() {
      try {
        await this.trtc.updateLocalVideo({ mute: false });
        this.isMutedVideo = false;
      } catch (error) {
      }
    },

    async unmuteAudio() {
      try {
        await this.trtc.updateLocalAudio({ mute: false });
        this.isMutedAudio = false;
      } catch (error) {
      }
    },

    async switchDevice(type, deviceId) {
      try {
        if (type === 'video' && this.camStatus === 'started') {
          await this.trtc.updateLocalVideo({
            option: { cameraId: deviceId },
          });
        }
        if (type === 'audio' && this.micStatus === 'started') {
          await this.trtc.updateLocalAudio({
            option: { microphoneId: deviceId },
          });
        }
      } catch (error) {
        console.error('switchDevice failed', error);
      }
    },

    startGetAudioLevel() {
      this.trtc.on(TRTC.EVENT.AUDIO_VOLUME, (event) => {
        event.result.forEach(({ userId, volume }) => {
          const isMe = userId === ''; // 当 userId 为空串时，代表本地麦克风音量。
          if (isMe) {
            // console.log(`my volume: ${volume}`);
          } else {
            // console.log(`user: ${userId} volume: ${volume}`);
          }
        });
      });
      this.trtc.enableAudioVolumeEvaluation(2000);
    },

    stopGetAudioLevel() {
      this.trtc && this.trtc.enableAudioVolumeEvaluation(-1);
    },

    installEventHandlers() {
      this.trtc.on(TRTC.EVENT.ERROR, this.handleError);
      this.trtc.on(TRTC.EVENT.REMOTE_USER_ENTER, this.handleRemoteUserEnter);
      this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, (event)=>{
        this.sendCustomGroupMsg(null, 'iamteacher|'+ this.account +'');
        const userId = event.userId;
        this.showTip(userId + '进入教室');
        this.inUserIds.push(userId);
        const streamType = event.streamType;
        // const remoteStream = window.remoteStream = event.stream;
        // 远端流订阅成功，在HTML页面中创建一个<video>标签，假设该标签ID为‘remote-video-view’
        // 播放该远端流
        let remoteVideoWrapEl = document.createElement('div');
        $(remoteVideoWrapEl).addClass("stuDiv");
        remoteVideoWrapEl.id = userId;
        remoteVideoWrapEl.style.position = 'relative';
        document.querySelector("#video_wrap").append(remoteVideoWrapEl);
        // remoteStream.play(remoteVideoWrapEl);
        this.trtc.startRemoteVideo({userId, streamType, view: userId});

        let stuDivs = $(".stuDiv");
        var stuLength = $(".stuDiv").length;
        if(stuLength>1){
          $("#video_wrap").removeClass("oneStu");
          if(stuLength>3){
            $("#video_wrap>div").addClass("nine");
            $("#local_video").addClass("nine");
          }else {
            $("#video_wrap>div").removeClass("nine");
            $("#local_video").removeClass("nine");
          }
        }else {
          $("#video_wrap").addClass("oneStu");            
        }

        $(".lmBtn").remove();

        let stuIndex = 0;
        
        for(let i=0;i<stuDivs.length;i++){
          if(stuDivs[i].nodeType==1 && stuDivs[i].nodeName=='DIV' && stuDivs[i].id!='local_video'){ 

            $(stuDivs[i]).attr('schoolId', this.inUserIds[stuIndex]);

            var t= '';
            $(stuDivs[i]).off("mousemove");

            $(stuDivs[i]).on('mousemove',function(){
              let eveLm = document.getElementById('lianmai'+i);
              $(eveLm).addClass("showMic");
              clearInterval(t);
              let timeNum = 0;
              t=setInterval(function  () {
                timeNum++;
                if(timeNum >=3){
                  $(eveLm).removeClass("showMic");
                  clearInterval(t);
                }
              },1000);


            });



            $(stuDivs[i]).on('mouseout',function(){
              let eveLm = document.getElementById('lianmai'+i);
              $(eveLm).removeClass("showMic");
            });

            

            if(document.getElementById('lianmai'+i)) { 
 
            } else {
              let lmdiv = $('<div class="lmBtn"><img src="https://xwimage001.oss-cn-beijing.aliyuncs.com/Test/%E8%BF%9E%E9%BA%A6.png"></div>');
              lmdiv.attr('id', 'lianmai'+i);
              lmdiv.attr('name', this.inUserIds[stuIndex]);
              // document.querySelector("#video_wrap").append(remoteVideoWrapEl);
              lmdiv.appendTo(stuDivs[i]);
              let eveLm = document.getElementById('lianmai'+i);


              //小按钮点击事件
              eveLm.onclick = ()=>{
                this.sendCustomGroupMsg(null, 'bizuiall');
                if($(eveLm).hasClass("check")){  //该闭麦了
                  $(eveLm).removeClass("check");
                  var imgUrl = '<img src="https://xwimage001.oss-cn-beijing.aliyuncs.com/Test/%E8%BF%9E%E9%BA%A6.png">'
                  $(eveLm).html(imgUrl);
                  this.nowSpeakUserId = '';
                  $(eveLm).parent().removeClass("fullscreen");
                }else{ //该开麦了         
                  setTimeout(()=>{
                    this.sendCustomGroupMsg(null, 'zhangzui|'+$(eveLm).attr("name")); 
                    this.nowSpeakUserId = $(eveLm).attr("name");
                  }, 500)   
                                
                  $(eveLm).addClass("check");
                  var imgUrl = '<img src="https://xwimage001.oss-cn-beijing.aliyuncs.com/Test/%E4%B8%8B%E9%BA%A6.png">'
                  $(eveLm).html(imgUrl);
                  $(eveLm).parent().addClass("fullscreen");  
                }
              }
            }
        
            stuIndex++;
          }
        }
      });
      this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_UNAVAILABLE, this.handleRemoteVideoUnavailable);
      this.trtc.on(TRTC.EVENT.KICKED_OUT, this.handleKickedOut);
      this.trtc.on(TRTC.EVENT.REMOTE_USER_EXIT, this.handleRemoteUserExit);
      this.trtc.on(TRTC.EVENT.REMOTE_AUDIO_AVAILABLE, this.handleRemoteAudioAvailable);
      this.trtc.on(TRTC.EVENT.SCREEN_SHARE_STOPPED, this.handleScreenShareStopped);
      this.trtc.on(TRTC.EVENT.NETWORK_QUALITY, event => {
        // console.log(`network-quality, uplinkNetworkQuality:${event.uplinkNetworkQuality}, downlinkNetworkQuality: ${event.downlinkNetworkQuality}`)
        // console.log(`uplink rtt:${event.uplinkRTT} loss:${event.uplinkLoss}`)
        // console.log(`downlink rtt:${event.downlinkRTT} loss:${event.downlinkLoss}`)
      })
      this.trtc.on(TRTC.CONNECTION_STATE_CHANGED, event => {
        const prevState = event.prevState;
        const curState = event.state;
        console.log(event)
        if(curState == "DISCONNECTED"){
          this.tsText = "连接断开";
          this.hideshow = true;
          this.tsDivShow = true; 
        }
        if(curState == "CONNECTING"){
          this.tsText = "正在连接中。。。";
          this.hideshow = true;
          this.tsDivShow = true; 
        }
        if(curState == "RECONNECTING"){
          this.tsText = "自动重连中。。。";
          this.hideshow = true;
          this.tsDivShow = true; 
        }
        if(curState == "CONNECTED"){
          this.tsText = "已连接";
          this.hideshow = true;
          this.tsDivShow = true; 
          setTimeout(()=>{
            this.hideshow = false;
            this.tsDivShow = false; 
          },2000)
        }

      });
    },

    uninstallEventHandlers() {
      this.trtc.off(TRTC.EVENT.ERROR, this.handleError);
      this.trtc.off(TRTC.EVENT.KICKED_OUT, this.handleKickedOut);
      this.trtc.off(TRTC.EVENT.REMOTE_USER_ENTER, this.handleRemoteUserEnter);
      this.trtc.off(TRTC.EVENT.REMOTE_USER_EXIT, this.handleRemoteUserExit);
      this.trtc.off(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, this.handleRemoteVideoAvailable);
      this.trtc.off(TRTC.EVENT.REMOTE_VIDEO_UNAVAILABLE, this.handleRemoteVideoUnavailable);
      this.trtc.off(TRTC.EVENT.REMOTE_AUDIO_UNAVAILABLE, this.handleRemoteAudioUnavailable);
      this.trtc.off(TRTC.EVENT.REMOTE_AUDIO_AVAILABLE, this.handleRemoteAudioAvailable);
      this.trtc.off(TRTC.EVENT.SCREEN_SHARE_STOPPED, this.handleScreenShareStopped);
    },

    handleError(error) {
      alert(error);
    },

    async handleKickedOut(event) {
      console.log(event)
      // this.showTip('用户异地登录，请重新登录'); 
      // this.trtc.destroy();
      // setTimeout(()=>{
      //     this.$router.push('/index');  
      // },1000)
      this.trtc = null;
      await this.exitRoom();
    },

    handleRemoteUserEnter(event) {
      const { userId } = event;
    },

    handleRemoteUserExit(event) {
    },

    handleRemoteVideoAvailable(event) {

      const { userId, streamType } = event;
      try {
        if (streamType === TRTC.TYPE.STREAM_TYPE_MAIN) {
          this.remoteUsersViews.push(`${userId}_main`);
          this.$nextTick(async () => {
            await this.trtc.startRemoteVideo({ userId, streamType, view: `${userId}_main` });
          });
        } else {
          this.remoteUsersViews.push(`${userId}_screen`);
          this.$nextTick(async () => {
            await this.trtc.startRemoteVideo({ userId, streamType, view: `${userId}_screen` });
          });
        }
      } catch (error) {
      }
    },

    handleRemoteVideoUnavailable(event) {
      const { streamType } = event;
      this.trtc.stopRemoteVideo({ userId: event.userId, streamType });
      
      const userId = event.userId;
      let leaveIndex = this.inUserIds.indexOf(userId);
      this.inUserIds.splice(leaveIndex, 1);

      document.getElementById(userId).remove();
      var stuLength = $(".stuDiv").length;
      if(stuLength>1){
        $("#video_wrap").removeClass("oneStu");
        if(stuLength>3){
          $("#video_wrap>div").addClass("nine");
          $("#local_video").addClass("nine");
        }else {
          $("#video_wrap>div").removeClass("nine");
          $("#local_video").removeClass("nine");
        }
      }else {
        $("#video_wrap").addClass("oneStu");
      }

      this.showTip(event.userId + '离开了房间');  
      // this.showTip(userId+ '离开了房间');

      if (streamType === TRTC.TYPE.STREAM_TYPE_MAIN) {
        this.remoteUsersViews = this.remoteUsersViews.filter(userId => userId !== `${event.userId}_main`);
      } else {
        this.remoteUsersViews = this.remoteUsersViews.filter(userId => userId !== `${event.userId}_screen`);
      }
    },

    handleRemoteAudioUnavailable(event) {
    },

    handleRemoteAudioAvailable(event) {
    },

    handleScreenShareStopped() {
      this.shareStatus = 'stopped';
    },

  }
}
</script>

<style>
* {
      margin: 0;
      padding: 0;
    }

    html,
    body,
    #app_box {
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: #222736;
    }

    .title {
      margin: 10px 0;
      text-align: center;
    }

    .text-right {
      text-align: right;
    }

    .promption {
      color: #A0A0A0
    }

    .im-log-box {
      width: 100%;
      height: 100%;
      /*border: 1px solid #999;*/
      margin: 0px;
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .im-log-box li {
      margin: 2px 5px;
      font-size: 14px;
      color: #666;
    }

    #video_wrap {
      /*height: 100px;*/
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      /*justify-content: space-between;*/
    }

    #video_wrap.oneStu {
      padding-top: 10%;
    }

    #video_wrap>div {
      width: 50%;
      height: 45%;
    }

    #video_wrap>div.nine {
      width: 33%;
      height: 33%;
    }

    #video_wrap #local_video.nine {
      width: 33%;
      height: 33%;
    }

    #paint_box {
      position: relative;
      width: 100%;
      /*height: 450px;*/
      height: 100%;
      border: 1px solid #888;
      overflow: hidden;
      box-sizing: content-box;
    }

    /*.el-header,
    .el-footer {
      padding: 0 !important;
    }*/

    .el-main {
      padding: 0 !important;
      overflow: hidden !important;
      height: 100%;
    }

    .el-container {
      height: 100%;
      overflow: hidden;
    }

    .el-tabs__header {
      margin: 0;
    }

    .el-icon-arrow-left,
    .el-icon-arrow-right {
      font-size: 20px;
    }

    .version-info {
      font-size: 14px;
      color: #606266;
    }
    .el-input {
      width: 180px;
      display: inline-block;
    }
    .img_she{position:absolute;width:30px;height:30px;top:10px;right:10px;cursor:pointer;z-index:2;}
    .img_yu{right:50px;}
    .sqbb{position:static !important;top:0 !important;left:0 !important;bottom:0 !important;width:100%;height:100%;}

    .livezhezhao{background-color:#000;position:fixed;z-index:99;left:0;top:0;right:0;bottom:0;width:100%;min-width:1300px;height:100%;opacity:0.5;filter: alpha(opacity=50);-moz-opacity: 0.5;}
    .cameraChoseDiv {
      width: 400px;
      height: 200px;
      background-color: #fff;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      /*display: none;*/
      z-index: 100;
      padding: 20px 20px;
      box-sizing: border-box;
      border-radius: 4px;
    }
    .tsDiv {
      width: 200px;
      height: 40px;
      background-color: #fff;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: none;
      z-index: 100;
      line-height: 40px;
      text-align: center;
      box-sizing: border-box;
      border-radius: 4px;
      color: #333;
    }
    .questionDiv {
      width: 500px;
      height: 300px;
      background-color: #fff;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 9999999;
      line-height: 30px;
      /* text-align: center; */
      padding: 0 20px;
      box-sizing: border-box;
      border-radius: 4px;
      color: #333;
    }
    .questionList {
      width: 100%;
      height: 200px;
      overflow-y: auto;
    }
    .questionDetail {
      margin: 10px auto;
    }
    .qustionFrom {
      font-size: 16px;
      font-weight: 700;
    }
    .questionContent {
      font-size: 14px;
      line-height: 30px;
      color: #666;
    }
    .ikonw {
      width: 80px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #07ADF5;
      color: #fff;
      font-size: 12px;
      border-radius: 4px;
      margin: 0 auto;
      cursor: pointer;
    }
    .xkDiv {
      width: 300px;
      height: 150px;
      background-color: #fff;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 100;
      line-height: 40px;
      text-align: center;
      box-sizing: border-box;
      border-radius: 4px;
      color: #333;
    } 
    .choseDiv {
      margin-bottom: 20px;
      width: 100%;
      line-height: 30px;
      height: 30px;
    }
    .choseDiv span {
      font-size: 12px;
      color: #333;
      display: inline-block;
      margin-right: 10px;
    }
    .choseDiv select {
      width: 300px;
      display: inline-block;
      height: 30px;
      line-height: 30px;
      text-align: right;
      /*direction: rtl;*/
    }
    .choseDiv select option {
      /*direction: ltr;*/
    }
    .cameraBtn {
      width: 80px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #F5CA1F;
      color: #fff;
      font-size: 12px;
      border-radius: 4px;
      margin: 0 auto;
      cursor: pointer;
    }
    .xkBtn {
      width: 80px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #FF6666;
      color: #fff;
      font-size: 12px;
      border-radius: 4px;
      margin: 10px 20px;
      cursor: pointer;
      display: inline-block;
    }
    .qxBtn {
      width: 80px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #F5CA1F;
      color: #fff;
      font-size: 12px;
      border-radius: 4px;
      margin: 10px 20px;
      cursor: pointer;
      display: inline-block;
    }
    .lmBtn {
      width: 80px;
      height: 80px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 999;
      cursor: pointer;
      display: none;
    }
    #video_wrap .fullscreen {
      width: 100%;
      height: 100%;
      position: fixed !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 9999;
      cursor: pointer;
    }
    .lmBtn img {
      width: 100%;
      height: 100%;
    }
    .lmBtn.showMic {
      display: block;
    }
    #video_wrap video {
      /*position:static !important;*/
      transform: rotateY(0) !important;
      object-fit: contain !important;

    }
    .schoolNameDiv {
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px 20px;
    }
    .nameDiv {
      font-size: 14px;
      color: #ff6666;
      line-height: 30px;
    }


</style>
