import Vue from 'vue'
import App from './App.vue'
import router from './router'
import newCommon from '@/components/common'
import axios from 'axios'
// import newCommon from '@/components/common'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import TRTC from 'trtc-sdk-v5';
// import '@/utils/aegis.js';


//1.Vue.use安装插件
// Vue.use(VueRouter)



Vue.config.productionTip = false
Vue.prototype.newCommon = newCommon
Vue.prototype.axios = axios
Vue.use(ElementUI);

TRTC.setLogLevel(2);

// TRTC.Logger.setLogLevel(TRTC.Logger.LogLevel.DEBUG);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
